<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list="['operation','status','product_info','promotion_info','give_info','reward_info']"
			ref="list" :submit_preprocessing="submit_preprocessing">
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加套餐</a-button>
			</template>
			<template slot="operation" slot-scope="data">
				<a v-auth="'change'" @click="edit(data.record)" v-if="data.record.can_change == 1">编辑</a>
				<a-divider v-auth="'change'" v-if="data.record.can_change == 1" type="vertical" />
				<a-popconfirm title="您确定要删除吗?" v-if="data.record.can_change == 1" v-auth="'delete'" @confirm="del(data.record)"><a>删除</a></a-popconfirm>
                <a-divider v-auth="{value:'change,delete',type:'reverse'}" type="vertical" />
				<a @click="seeInfo(data.record)" v-auth="{value:'change,delete',type:'reverse'}">详情</a>
			</template>
			<template slot="status" slot-scope="data">
				<a-switch @change='changeStatus(data.record)' :checked="data.record.status == 1 || data.record.can_change != 1?true:false" />
			</template>
			<template slot="product_info" slot-scope="data">
				<a @click="see_goods_list(data.record,1)">{{data.record.product_info}}</a>
			</template>
			<template slot="promotion_info" slot-scope="data">
				<a @click="see_goods_list(data.record,2)">{{data.record.promotion_info}}</a>
			</template>
			<template slot="give_info" slot-scope="data">
				<a @click="see_goods_list(data.record,3)">{{data.record.give_info}}</a>
			</template>
			<template slot="reward_info" slot-scope="data">
				<a @click="see_goods_list(data.record,4)">{{data.record.reward_info}}</a>
			</template>

		</TableList>
		<EditPop height='400px' width='50%' ref='EditPop' :title="edit_type?'编辑套餐':'添加套餐'" @handleSubmit="add_submit"
			:form_data="form_data" :visible.sync="add_visible">
			<template slot='goods_info'>
				<div>
					<a-button style='margin-bottom:10px' @click="add_material(1)" type="primary">选择本品品项</a-button>
					<a-table v-if='material_list.length' :dataSource="material_list" :columns="columns_goods_list">
						<template slot="goods_num" slot-scope="text, record, index">
							<a-input type="number" name="num" v-model:value="record.goods_num" />
						</template>
						<template slot="goods_price" slot-scope="text, record, index">
							<a-input type="number" name="num" v-model:value="record.goods_price" />
						</template>

						<template slot="op" slot-scope="text, record, index">
							<span style='color:#FF3B30;' @click='del_goods(record,index,material_list)'>删除
							</span>
						</template> </a-table>
				</div>
			</template>
			<template slot='goods_info2'>
				<div>
					<a-button style='margin-bottom:10px' @click="add_material(2)" type="primary">选择促销品项</a-button>
					<a-table v-if='material_list2.length' :dataSource="material_list2" :columns="columns_goods_list">
						<template slot="goods_num" slot-scope="text, record, index">
							<a-input type="number" name="num" v-model:value="record.goods_num" />
						</template>
						<template slot="goods_price" slot-scope="text, record, index">
							<a-input type="number" name="num" v-model:value="record.goods_price" />
						</template>

						<template slot="op" slot-scope="text, record, index">
							<span style='color:#FF3B30;' @click='del_goods(record,index,material_list2)'>删除
							</span>
						</template> </a-table>
				</div>
			</template>
			<template slot='goods_info3'>
				<div>
					<a-button style='margin-bottom:10px' @click="add_material(3)" type="primary">选择搭赠品项</a-button>
					<a-table v-if='material_list3.length' :dataSource="material_list3" :columns="columns_goods_list">
						<template slot="goods_num" slot-scope="text, record, index">
							<a-input type="number" name="num" v-model:value="record.goods_num" />
						</template>
						<template slot="goods_price" slot-scope="text, record, index">
							<a-input type="number" name="num" v-model:value="record.goods_price" />
						</template>

						<template slot="op" slot-scope="text, record, index">
							<span style='color:#FF3B30;' @click='del_goods(record,index,material_list3)'>删除
							</span>
						</template> </a-table>
				</div>
			</template>
			<template slot='goods_info4'>
				<div>
					<a-button style='margin-bottom:10px' @click="add_material(4)" type="primary">选择提货奖励品项</a-button>
					<a-table v-if='material_list4.length' :dataSource="material_list4" :columns="columns_goods_list">
						<template slot="goods_num" slot-scope="text, record, index">
							<a-input type="number" name="num" v-model:value="record.goods_num" />
						</template>
						<template slot="goods_price" slot-scope="text, record, index">
							<a-input type="number" name="num" v-model:value="record.goods_price" />
						</template>

						<template slot="op" slot-scope="text, record, index">
							<span style='color:#FF3B30;' @click='del_goods(record,index,material_list4)'>删除
							</span>
						</template> </a-table>
				</div>
			</template>
		</EditPop>
		<!-- <SelectMaterial @submit="select_material_submit" :visible.sync="material_visible"></SelectMaterial> -->
		<SelectMaterialGood ref='material' chooseType='checkbox' @submit="select_material_submit"
			:visible.sync="material_visible">
		</SelectMaterialGood>

		<a-modal title="品项详情" :maskClosable='true' :visible="see_visible" @cancel='see_visible=false'
			@ok='see_visible=false'>
			<a-table :dataSource="see_material_list" :columns="columns_goods_list2">

			</a-table>

		</a-modal>

        <EditPop height='400px' width='50%' ref='EditPop' title="详情" @handleSubmit="seeType = false"
			:form_data="form_data" :visible.sync="seeType">
			<template slot='goods_info'>
				<div>
					<a-table v-if='material_list.length' :dataSource="material_list" :columns="columns_goods_list">
						<template slot="goods_num" slot-scope="text, record, index">
							<a-input type="number" name="num" :value="record.goods_num" readonly/>
						</template>
						<template slot="goods_price" slot-scope="text, record, index">
							<a-input type="number" name="num" :value="record.goods_price" readonly/>
						</template>
                     </a-table>
				</div>
			</template>
			<template slot='goods_info2'>
				<div>
					<a-table v-if='material_list2.length' :dataSource="material_list2" :columns="columns_goods_list">
						<template slot="goods_num" slot-scope="text, record, index">
							<a-input type="number" name="num" :value="record.goods_num" readonly/>
						</template>
						<template slot="goods_price" slot-scope="text, record, index">
							<a-input type="number" name="num" :value="record.goods_price" readonly/>
						</template>
                    </a-table>
				</div>
			</template>
			<template slot='goods_info3'>
				<div>
					<a-table v-if='material_list3.length' :dataSource="material_list3" :columns="columns_goods_list">
						<template slot="goods_num" slot-scope="text, record, index">
							<a-input type="number" name="num" :value="record.goods_num" readonly/>
						</template>
						<template slot="goods_price" slot-scope="text, record, index">
							<a-input type="number" name="num" :value="record.goods_price" readonly/>
						</template>

					</a-table>
				</div>
			</template>
			<template slot='goods_info4'>
				<div>
					<a-table v-if='material_list4.length' :dataSource="material_list4" :columns="columns_goods_list">
						<template slot="goods_num" slot-scope="text, record, index">
							<a-input type="number" name="num" v-model:value="record.goods_num" readonly/>
						</template>
						<template slot="goods_price" slot-scope="text, record, index">
							<a-input type="number" name="num" v-model:value="record.goods_price" readonly/>
						</template>
                    </a-table>
				</div>
			</template>
		</EditPop>
	</div>

</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import SelectMaterialGood from "@/components/SelectMaterialGood";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";

	import {
		getOrderMealList,
		changeOrderMealStatus,
		getOrderMealDetail,
		delOrderMeal,
		saveOrderMeal,
		getOrderMealCateListSelect
	} from "@/api/goods";

	import {
		message
	} from 'ant-design-vue'
	let columns_goods_list = [{
			title: "ID",
			dataIndex: "goods_id",
		}, {
			title: "品项",
			dataIndex: "goods_title",
		},
		{
			title: "数量",
			dataIndex: "goods_num",
			scopedSlots: {
				customRender: "goods_num",
			},

		},
		{
			title: "价格",
			dataIndex: "goods_price",
			scopedSlots: {
				customRender: "goods_price"
			},
		},
		{
			title: "操作",
			dataIndex: "op",
			scopedSlots: {
				customRender: "op",
			},

		},
	]
	
	let columns_goods_list2 = [{
			title: "ID",
			dataIndex: "goods_id",
		}, {
			title: "品项",
			dataIndex: "goods_title",
		},
		{
			title: "数量",
			dataIndex: "goods_num",
			scopedSlots: {
				customRender: "goods_num",
			},
	
		},
		{
			title: "价格",
			dataIndex: "goods_price",
			scopedSlots: {
				customRender: "goods_price"
			},
		},
		
	]
	let columns = [{
			title: "ID",
			dataIndex: "id",

		},
		{
			title: "套餐名称",
			dataIndex: "title",
		},
		{
			title: "本品",
			dataIndex: "product_info",
			scopedSlots: {
				customRender: "product_info"
			},
		},
		{
			title: "促销",
			dataIndex: "promotion_info",
			scopedSlots: {
				customRender: "promotion_info"
			},
		},
		{
			title: "搭赠",
			dataIndex: "give_info",
			scopedSlots: {
				customRender: "give_info"
			},
		},
		
		{
			title: "提货奖励",
			dataIndex: "reward_info",
			scopedSlots: {
				customRender: "reward_info"
			},
		},
{
			title: "件数",
			dataIndex: "goods_num",
			
		},
		{
			title: "创建时间",
			dataIndex: "create_time",
		},
		{
			title: "状态",
			dataIndex: "status",
			scopedSlots: {
				customRender: "status"
			},
		},
		{
			title: "有效期",
			dataIndex: "valid_range",
		},

		{
			title: "所属等级",
			dataIndex: "cate_name",
		},
        {
            title: "创建人",
            dataIndex: "create_user"
        },
		{
			title: "更新时间",
			dataIndex: "update_time",
		},

		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			},
			fixed: 'right'
		},
	];


	export default {
		name: "Index",
		components: {
			EditPop,
			SelectMaterialGood,
			Form,
			TableList,
		},
		data() {
			return {
				see_material_list: [],
				see_visible: false,
				material_visible: false,
				columns_goods_list,
				columns_goods_list2,
				material_list: [],
				material_list2: [],
				material_list3: [],
				material_list4: [],
				goods_code: '',
				goods_code_num: '',
				code_num: '',
				edit_type: 1,
				get_table_list: getOrderMealList,
				submit_preprocessing: {
					array_to_string: ['department', 'customer_type']
				},
				config: this.$config,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				details: {},
				visible: false,
				add_visible: false,
				form_data_seo: {
					list: [{
							type: "text",
							name: "keyword",
							title: "套餐名称",
							placeholder: '套餐名称',
							options: {}
						},

						{
							type: 'select',
							title: '状态',
							name: 'status',
							options: {},
							list: [{
									key: 1,
									value: "启用"
								},
								{
									key: 0,
									value: "禁用"
								},
							]
						},
						{
							type: "range_date",
							name: "range_date1",
							title: "有效期时间",
							options: {},
							start: {
								name: 'start_valid_time'
							},
							end: {
								name: 'end_valid_time'
							},
						},
						{
							type: "range_date",
							name: "range_date2",
							title: "创建时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},

					],
					...this.$config.form_data_seo
				},

				form_data: {
					title: '添加套餐',
					 item_margin_bottom: '4px',
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {
								initialValue: ''
							},
						},
						{
							type: 'text',
							name: 'title',
							title: '套餐名称',
							options: {
								initialValue: ''
							},
						},
						{
							type: 'cascader',
							name: 'pid',
							title: '所属套餐等级',
							options: {
								initialValue: []
							},
							list: [],
							replaceFields: {
								label: 'title',
								value: 'id',
								children: 'children'
							},
						},
						{
							type: 'radio',
							name: 'status',
							title: '套餐状态',
							list: [{
									key: 1,
									value: "启用"
								},
								{
									key: 2,
									value: "禁用"
								}
							],
							options: {},
						},
						{
							type: "range_date",
							name: "range_date",
							title: "起止时间",
							options: {},
							start: {
								name: 'start_valid_time'
							},
							end: {
								name: 'end_valid_time'
							},
						},
						{
							type: 'slot',
							name: 'goods_info',
							title: '本品',
							options: {},
						},
						{
							type: 'slot',
							name: 'goods_info2',
							title: '促销',
							options: {},
						},
						{
							type: 'slot',
							name: 'goods_info3',
							title: '搭赠',
							options: {},
						},
						{
							type: 'slot',
							name: 'goods_info4',
							title: '提货奖励',
							options: {},
						},
					]
				},
				edit_type: false,
				add_type: 1,
                seeType:false
			};
		},
		async created() {
			this.get_pid()
		},
		methods: {
			del_goods(data, index, arr) {
				arr.splice(index, 1)
			},
			see_goods_list(data, type) {
				getOrderMealDetail({
					data: {
						id: data.id
					}
				}).then(res => {
					if (type == 1) {
						this.see_material_list = res.data.list.product_info
					}
					if (type == 2) {
						this.see_material_list = res.data.list.promotion_info
					}
					if (type == 3) {
						this.see_material_list = res.data.list.give_info
					}
					if (type == 4) {
						this.see_material_list = res.data.list.reward_info
					}
					this.see_visible = true
				})
			},
			get_pid() {
				getOrderMealCateListSelect({
					data: {
						type: 'select'
					}
				}).then(res => {
					this.form_data.list[2].list = res.data.list
				})
			},
			select_material_submit({
				data
			}) {
				if (this.add_type == 1) {
					this.material_list = []
					this.material_list = data.map(info => {
						info.goods_id = info.id
						info.goods_num = info.goods_num
						info.goods_price = info.goods_price
						return info
					})
				}
				if (this.add_type == 2) {
					this.material_list2 = []
					this.material_list2 = data.map(info => {
						info.goods_id = info.id
						info.goods_num = info.goods_num
						info.goods_price = info.goods_price
						return info
					})
				}
				if (this.add_type == 3) {
					this.material_list3 = []
					this.material_list3 = data.map(info => {
						info.goods_id = info.id
						info.goods_num = info.goods_num
						info.goods_price = info.goods_price
						return info
					})
				}
				if (this.add_type == 4) {
					this.material_list4 = []
					this.material_list4 = data.map(info => {
						info.goods_id = info.id
						info.goods_num = info.goods_num
						info.goods_price = info.goods_price
						return info
					})
				}

			},
			add_material(type) {
				let that = this
				this.material_visible = true
				this.add_type = type
				this.$nextTick(() => {
					if (that.add_type == 1) {
						that.$refs.material.selectedRowKeys = that.material_list.map(it => {
							return it.id
						})
						that.$refs.material.temp_list = that.material_list.length > 0 ? JSON.parse(JSON.stringify(
							that.material_list)) : []
					}
					if (that.add_type == 2) {
						that.$refs.material.selectedRowKeys = that.material_list2.map(it => {
							return it.id
						})
						that.$refs.material.temp_list = that.material_list2.length > 0 ? JSON.parse(JSON.stringify(
							that.material_list2)) : []
					}
					if (that.add_type == 3) {
						that.$refs.material.selectedRowKeys = that.material_list3.map(it => {
							return it.id
						})
						that.$refs.material.temp_list = that.material_list3.length > 0 ? JSON.parse(JSON.stringify(
							that.material_list3)) : []
					}
					if (that.add_type == 4) {
						that.$refs.material.selectedRowKeys = that.material_list4.map(it => {
							return it.id
						})
						that.$refs.material.temp_list = that.material_list4.length > 0 ? JSON.parse(JSON.stringify(
							that.material_list4)) : []
					}
				})
			},
			add_submit(e) {
				if (this.material_list.length == 0) {
					message.error("请选择本品品项")
					return false
				}
				saveOrderMeal({
					data: {
						...e.values,
						pid: e.values.pid ? e.values.pid[e.values.pid.length - 1] : '',
						product_info: this.material_list.map(it => {
							return {
								goods_id: it.goods_id,
								goods_title: it.goods_title,
								goods_num: it.goods_num,
								goods_price: it.goods_price,
							}
						}),
						promotion_info: this.material_list2.map(it => {
							return {
								goods_id: it.goods_id,
								goods_title: it.goods_title,
								goods_num: it.goods_num,
								goods_price: it.goods_price,
							}
						}), //促销
						give_info: this.material_list3.map(it => {
							return {
								goods_id: it.goods_id,
								goods_title: it.goods_title,
								goods_num: it.goods_num,
								goods_price: it.goods_price,
							}
						}), //搭赠
						reward_info: this.material_list4.map(it => {
							return {
								goods_id: it.goods_id,
								goods_title: it.goods_title,
								goods_num: it.goods_num,
								goods_price: it.goods_price,
							}
						}), //奖励
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list();
					this.clear_info()
				})
			},
			success() {
				this.$refs.list.get_list();
			},

			changeStatus(e) {
				changeOrderMealStatus({
					data: {
						"id": e.id, //id
						"status": e.status == 1 ? 0 : 1 //状态 1启用 0禁用
					}
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			add() {
                this.form_data.list.forEach(item=>{
                    item.disabled = false
                })
				this.clear_info()
				this.edit_type = false
				this.add_visible = true
			},
			clear_info() {
				this.form_data.list.forEach(item => {
					item.options.initialValue = ''
					this.$forceUpdate()
				})
				this.material_list = []
				this.material_list2 = []
				this.material_list3 = []
				this.material_list4 = []
				this.edit_type = false
				this.add_visible = false
			},

			edit(record) {
				let that = this
				this.edit_type = true
				this.clear_info()
                this.form_data.list.forEach(item=>{
                    item.disabled = false
                })
				getOrderMealDetail({
					data: {
						id: record.id
					}
				}).then(res => {
					this.add_visible = true
					this.material_list = res.data.list.product_info.map(it => {
						it.id = it.goods_id
						return it
					})
					this.material_list2 = res.data.list.promotion_info.map(it => {
						it.id = it.goods_id
						return it
					})
					this.material_list3 = res.data.list.give_info.map(it => {
						it.id = it.goods_id
						return it
					})
					this.material_list4 = res.data.list.reward_info.map(it => {
						it.id = it.goods_id
						return it
					})
					this.form_data.list.forEach(item => {
						if (item.name == 'pid') {
							item.options.initialValue = res.data.list.pid == 0 ? [] : [res.data.list.pid]
						} else if (item.type == 'range_date') {
							item.options.initialValue = [res.data.list.start_valid_time, res.data.list
								.end_valid_time
							]
							this.$nextTick(r => {
								that.$refs.EditPop.$refs.form.setFieldsValue({
									start_valid_time: res.data.list.start_valid_time
								});
								that.$refs.EditPop.$refs.form.setFieldsValue({
									end_valid_time: res.data.list.end_valid_time
								});
							})

						} else {
							item.options.initialValue = res.data.list[item.name]
						}
					})
				})
			},
			del(record) {
				delOrderMeal({
					data: {
						id: record.id
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
            // 查看详情
            seeInfo(record){
                this.seeType = true
                let that = this
				this.clear_info()
                this.form_data.list.forEach(item=>{
                    item.disabled = true
                })
				getOrderMealDetail({
					data: {
						id: record.id
					}
				}).then(res => {
					this.seeType = true
					this.material_list = res.data.list.product_info.map(it => {
						it.id = it.goods_id
						return it
					})
					this.material_list2 = res.data.list.promotion_info.map(it => {
						it.id = it.goods_id
						return it
					})
					this.material_list3 = res.data.list.give_info.map(it => {
						it.id = it.goods_id
						return it
					})
					this.material_list4 = res.data.list.reward_info.map(it => {
						it.id = it.goods_id
						return it
					})
					this.form_data.list.forEach(item => {
						if (item.name == 'pid') {
							item.options.initialValue = res.data.list.pid == 0 ? [] : [res.data.list.pid]
						} else if (item.type == 'range_date') {
							item.options.initialValue = [res.data.list.start_valid_time, res.data.list
								.end_valid_time
							]
							this.$nextTick(r => {
								that.$refs.EditPop.$refs.form.setFieldsValue({
									start_valid_time: res.data.list.start_valid_time
								});
								that.$refs.EditPop.$refs.form.setFieldsValue({
									end_valid_time: res.data.list.end_valid_time
								});
							})

						} else {
							item.options.initialValue = res.data.list[item.name]
						}
					})
				})
            }

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.drawer_title {
		padding: 15px 0;
	}

	.use_status {
		display: flex;
		align-items: center;

		.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: @success-color;
			margin-right: 8px;
		}

		.red {
			background: @error-color;
		}
	}

	.input_item {
		display: flex;
		align-items: center;
		margin-top: 14px;

		.text {
			white-space: nowrap;
			font-size: 14px;
			margin-right: 4px;
		}
	}

	.tips {
		margin-top: 8px;
		color: @error-color;
		font-size: 14px;
	}

	.code_box {
		display: flex;
		align-items: center;

		div {
			flex: 1;
		}
	}
</style>